@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

/* CSS Document */
body {
    text-align: center;
/*    background: #ffffff;*/
    background: #ffffff;
    font-family: Verdana, Geneva, Charcoal, Helvetica, Arial, sans-serif;
    margin: 0 auto;
    padding: 0;
    overflow-y: scroll;
}

.walletButton {
    font-size: 6px;
    line-height: 100%;

}

.walletButtonTwo, .walletButton {
/*    background:pink;*/
    -webkit-animation: glow 1.5s ease-in-out infinite alternate;
    -moz-animation: glow 1.5s ease-in-out infinite alternate;
        animation: glow 1.5s ease-in-out infinite alternate, fadeIn 1s;
/*    animation: fadeIn 5s;*/
}

.mintTitle {
    font-size: small;
    line-height: 100%;
    left: 30%;
    width: 40%;
    top: 30%;
    text-align: center;
    position: absolute;
    -webkit-animation: glow 2.5s ease-in-out infinite alternate;
    -moz-animation: glow 2.5s ease-in-out infinite alternate;
    /*    animation: glow 0.5s ease-in-out infinite alternate, fadeIn 5s;*/
/*    animation: glow 2.5s ease-in-out infinite alternate, fadeIn 1s;*/
}

.mintTitleTop {
    font-size: small;
    line-height: 100%;
    left: 15%;
    top: 15%;
    position: absolute;
    -webkit-animation: glow 0.8s ease-in-out infinite alternate;
    -moz-animation: glow 0.8s ease-in-out infinite alternate;
    /*    animation: glow 0.5s ease-in-out infinite alternate, fadeIn 5s;*/
    animation: glow 0.8s ease-in-out infinite alternate, fadeIn 2s, growIn 2s;
}

.mintTitleSub {
    font-size: 8px;
    line-height: 100%;
    left: 30%;
    width: 40%;
    top: 35%;
    text-align: center;
    position: absolute;
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    /*    animation: glow 0.5s ease-in-out infinite alternate, fadeIn 10s;*/
    animation: glow 1s ease-in-out infinite alternate, fadeIn 2s, growIn 2s;
}

@media (max-width: 768px) {
    .mintTitle {
        font-size: small;
        width: 100%;
        left: 0;
        top: 30%;
    }

    

    .walletButton {
        width: 80% !important;
        left: 10% !important;
        font-size: 5px;
        
    }

    .mintTitleTop {
        top: 12%;
    }
    .mintTitleSub {
        width: 60%;
        left: 20%;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes growIn {
    0% {
        transform: scale(0.1,0.1);
    }

    100% {
        transform: scale(1,1);
    }
}

.nav-link {
    font-size: medium;
}

.nav-link-sub {
    font-size: small;
    margin-left: 5px;
}


.vloxLogo {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 2s;
    /* When the animation is finished, start again */
    animation-iteration-count: infinite;
    box-shadow: 0px 10px 20px black;
    width: 50%;
    display: block;
    margin: auto;
    border-radius: 20px;
}

.vloxLogoSmall {
    display:block;
}

.galleryImages{
    width:100%;
}


.vloxLogoSmall, .galleryImages img {
    /*    box-shadow: 0px 10px 20px black;*/
    width: 25%;
    margin: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 20px;
}


/*.container {
    width: 200px;
    height: 200px;
    position: relative;
    margin: 20px;
}

.box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.8;*/ /* for demo purpose  */
/*}*/

.stack-top {
    z-index: 9;
    margin: 20px; /* for demo purpose  */
}


.walletButton {
/*    box-shadow: 0px 0px 1px 1px greenyellow;*/
    /*box-shadow: 0px 3px 6px black;*/
    /*    background-color:black !important;
    color:white;*/
/*    background:pink !important;*/
    border: none;
}

@keyframes shake {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }

    25% {
        transform: translate(0px, 1px) rotate(0deg);
    }

    50% {
        transform: translate(0px, -1px) rotate(0deg);
    }

    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}

.playerContainer {
    border-radius: 10px;
    border: 10px black solid;
    background: black;
    box-shadow: 0px 20px 40px rgba(0,0,0,0.4);
    display: block;
    width:100%;
/*    background-image: url(/images/vloxinvert.jpeg);*/
}



.featuresTable {
    padding-left: 5px;
    vertical-align: middle;
}

.image {
    max-width: 100%;
    max-height: 100vh;
    margin: auto;
}

.centralMainPage, .centralMainPagePlayer,.centralMintPage {
    /*margin-top: 0px !important;*/
    /*    width: 80%;*/
    display: block;
    margin: auto;
}

.centralMainPage, .centralMainPagePlayer{
    margin-top: 5vh !important;
}
.floatright{
    float:right;
}
.closeButton{
    position:absolute;
    top:2px;
    right:10px;
    border:none!important;
    margin:0px!important;
    padding:0px!important;
}

.mintheader {
    font-family: 'VT323', monospace;
    font-size: xx-large;
    height:10px;
}

.screenAccountModal {
    float: right;
/*    margin-right: 5%;*/
    border: 1px dashed darkgreen;
    padding: 10px;
    border-radius: 10px;
    position: absolute;
    top: 30%;
    right: 320px;
}

.screenAccountModalTwo {
    /*    float: right;*/
    /*    margin-right: 5%;*/
    border: 1px dashed black;
    position: relative;
    padding: 10px;
    border-radius: 10px;
    display: block;
    width: 60%;
    margin: auto;
    margin-top: 20px;
    /*    position: absolute;*/
    /*    top: 30%;*/
    /*    right: 320px;*/
}

@media (max-width: 768px) {
    .screenAccountModal {
        top: 30%;
        left: 20%;
        float: left;
        width:60%;
    }

/*    .screenAccountModalTwo {
        margin-top: 20px;
    }*/

    .galleryImages{
        padding-top:40px !important;
    }

}


    .screenAccountModalTwo button {
        border: 1px dashed black;
        background:none;
        border-radius:10px;
    }

.centralMintPage {
    margin-top: 50px !important;

    background-image: url("https://inkha.s3.eu-west-2.amazonaws.com/monitor5.png");

    border-radius: 20px;
    background-position: top; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: contain; /* Resize the background image to cover the entire container */

    color: darkgreen;
    font-family: 'VT323', monospace !important;
    font-size: large;
    text-align: left;
/*    padding-left: 30%;*/
    position: relative;
    min-height: 100vh;
    left:0px;
    height: 100vh;
    width: 100vh;
    animation: textShadow 5s infinite;
}

@media (max-width: 768px) {
    .centralMintPage {
        margin-top: 0px !important;
        background-image: none;
        margin-top: 0px !important;
        background: black;
        left: -15px;
        width: 110%;
        font-size: large !important;
        border-radius: 0px;
        top: -20px;
    }
}

    .centralMintPage:hover {
        cursor: crosshair;
        caret-color: darkgreen;
    }

    .centralMintPage button {
/*        -webkit-animation: glow 0.5s ease-in-out infinite alternate;*/
/*        -moz-animation: glow 0.5s ease-in-out infinite alternate;*/
/*        animation: glow 0.5s ease-in-out infinite alternate;*/
        
    }

/*    @-webkit-keyframes glow {
        from {
        text-shadow: 0 0 1px #fff, 0 0 2px #fff;
    }

    to {
        text-shadow: 0 0 2px #fff, 0 0 3px darkgreen;
    }
    }*/

    .centralMintPage button {
        color: darkgreen;
        border: darkgreen 1px dashed;
        background: none;
        border-radius: 10px;
        animation: textShadow 3s infinite, fadeIn 0.5s;
    }



    .centralMintPage select, .centralMintPage select option {
        color: darkgreen;
        border: darkgreen 1px dashed;
        background: black;
        border-radius: 10px;
        font-family: 'VT323', monospace !important;
        font-size: large;
        padding: 4px;
        user-select: none;
        animation: textShadow 3s infinite, fadeIn 0.5s;
    }


    .centralMainPagePlayer {
        /*    background-color:rgba(1,1,1,0.1);*/
        border-radius: 20px;
        /*    padding:20px;*/
        /*    width:100%;*/
    }
.navbar {
    display: block;
    margin: auto;
}

.test{
    background-color:pink;
    height:200px;
    width:100%;
}


/* screen width > 768 limit width to 568 - 768 is to match other stuff that bootstrap does at this size */
@media (min-width: 768px) {
    .centralMainPage, .centralMainPagePlayer, .navbar {
        width: 568px;
    }

}

img {
    max-width: 100%;
    width: 100%;
    object-fit: contain;
}



.mainPage p {
    width: 100% !important;
    display: block;
    margin: auto;
}

#header {
    text-align: center;
    margin: 0 auto;
    /*    max-width: 800px;*/
}

.top {
    text-align: left;
    height: 30px;
}

img.image-gallery-image {
    max-height: 400px !important;
    height: 400px !important;
    background-color: #212529 !important;
}

.ohbotLink {
    vertical-align: middle !important;
    padding-top: 8px;
    padding-left: 15px;
    padding-right: 10px;
}

.dropdown-menu {
    background-color: #2d2d2d !important;
    m: 20px;
}

.fullscreenButton {
    background-color: transparent;
    color: white;
/*    float: right;*/
    text-align:right;
    border: none;
    display:block;
    width:50%;
    height:10px;
    font-size:small;
    margin-bottom:10px;
/*    margin-top:10px;*/
}

.loadingText {
    background-color: transparent;
    width: 50%;
    height: 10px;
    float: left;
    color: white;
    margin: 0px;
    padding: 0px;
    border: none;
    font-size: small;
    text-align:left;
    margin-bottom: 10px;
}

.walletStuff{
    margin:100px;
    margin:auto;
    display:block;
    margin-top:20px;
}

.walletButton, .walletButtonTwo {
    background: 0px;
    border-radius: 10px !important;
/*    text-align:left !important;*/
    /*    border: 1px dashed darkgreen;*/
    /*    padding:10px;*/
/*    position: absolute;*/
}

.relativeWallet {
    width: 40%;
    height: 5%;
    left: 30%;
    bottom: 44%;
    position: absolute;
}

/*.walletButtonTwo {
    background: 0px;
    border-radius: 10px !important;*/
/*    border: 1px dashed black;*/
    /*    padding:10px;*/
/*    position: absolute;*/
    /*width: 40%;
    height: 5%;
    left: 30%;*/
/*    bottom: 44%;*/
/*}*/

.instructionsButton {
    border: none;
    /*    padding-top: 100px;*/
    background: transparent;
    display: block;
    text-align: center;
    width: 100%;
    margin: 0px;
    margin: auto;
    border-radius: 10px;
    font-size:x-large;
    margin-top:20px;
}

.instructionsSection {
    font-size: small;
    padding: 0px;
    text-align: left;
    /*    margin: 20px;*/
    float: left;
    margin-top: 20px;
    margin-bottom: 200px;
    width: 100%;
/*    background-color: rgba(1,1,1,0.1);*/
    /*    display: block;*/
}

    .instructionsSection h4 {
        font-size: x-large;
        margin-bottom: 30px;
        margin-top: 30px;
    }

    .instructionsSection h2 {
        font-size: x-large;
        text-align: left;
        padding: 0px;
        margin: 0px;
        margin-bottom: 30px;
        margin-top: 30px;
    }

    .instructionsSectionIcon {
        padding:20px;
        border-radius:20px;
    }

    .instructionsSectionImage {
        padding-top: 10px;
        padding-bottom: 10px;
/*        border-radius: 35px;*/
        width:70%;   
    }

.wash {
    height: 344px;
    width: 360px;
    min-height: 344px;
    text-align: left;
}

    .wash ul {
        text-align: left;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

        .wash ul li {
            margin: 0;
            padding: 0;
        }

            .wash ul li a {
                display: inline-block;
                color: rgb(149,7,21);
                padding: 10px;
                text-decoration: none;
            }

                .wash ul li a:hover {
                    color: #565656;
                }
.leftPara {
    padding: 10px !important;
}
.leftPara p{
    margin-top:10px;
}
.aboutTheProject {
    max-width: 800px;
    color: black;
    background-color: #9b9b9b10;
    border-radius: 10px;
    margin: 0 auto;
    text-align: left;
}

    .aboutTheProject img{
        margin-top: 25px;
        border-radius:5px;
    }
    .aboutTheProject h4 {
        margin-top: 20px;
    }

    .aboutTheProject td p {
        background-color: transparent;
    }
.theTeam {
    max-width: 800px;
    color: black;
    background-color: #9b9b9b10;
    border-radius: 10px;
    margin: 0 auto;
    text-align: left;
}
    .theTeam img {
        margin-top: 20px;
    }

    .theTeam td p {
        padding: 10px !important;
        background-color: transparent;
    }

    .theTeam h4 {
        text-align:center;
        width:100%;
        margin:0 !important;
    }

.teamImage {
    width: 40%;
    margin: auto;
}
.nftTitle {
    margin-top: 10px;
    margin-bottom: 5px;
}
.nftImages img {
    border-radius:10px;
}

.blurbHidden {
    display: none;
}
.blurb {
/*    max-width: 800px;*/
    color: pink;
    background-color: white;
    margin: 0 auto;
    text-align: left;
    width:100%;
}

.blurb p {
    background-color: white;
    padding: 1px;
}

.blurb ul {
    color: black;
    font-size: 12px;
}

.blurb tr {
    color: black;
    font-size: 12px;
}

.help {
    /*    max-width: 800px;*/
    color: pink;
    background-color: white;
    margin: 0 auto;
    width: 100%;
    text-align: left;
}

.help p {
    background-color: white;
    padding: 1px;
}

.help img {
    margin: 2px 0 2px 0;
    /*text-align: left;*/
    object-fit: scale-down;
    border-radius:10px;
    /*object-position: left;*/
    width:auto;
}

.help h1 {
    padding: 2px;
    margin: 0;
}

.help h2 {
    color: white;
    background-color: lightgray;
    margin: 20px 0 5px 0;
}

.help h3 {
    font-weight: bold;
    color: black;
    margin: 10px 0 5px 0;
}

.help p {
    margin: 0;
}


ul li {
    /*    background-image: url("/"sqpurple.gif);*/
    background-repeat: no-repeat;
    background-position: 0px 5px;
    padding-left: 14px;
}

ul a {
    color: rgb(149,7,21);
    text-decoration: none;
}

    ul a:hover {
        color: #4d4444;
    }

.email {
    height: 40px;
    margin-bottom: 2px;
}

table.homePageTable {
    width: 90%;
    display: block;
    padding: 0px;
    margin: auto;
}

.homeImage {
    width: 100%;
    height: auto;
    display: block;
    padding: 0px;
    margin: auto;
    padding: 5px;
    padding-top: 5px;
}

.floatLeft {
    float: left;
}

.floatRight {
    float: right;
    /*    padding-left: 5px;*/
}



.miniheader h2 {
    font-size: 16px;
    color: rgb(149,7,21);
    max-width: 800px;
    margin: 0 auto;
    text-align: left;
}

.info {
    display: block;
    height: 100% !important;
    max-width: 400px;
    line-height: normal;
    white-space: nowrap;
    overflow: auto;
    font-family: sans-serif !important;
    font-weight: normal !important;
}

.menuIcon {
    /*    width:20px;*/
    height: 30px;
}

.navbar {
    text-align: left;
    font-size: 14px;
    background: #2d2d2d;
    -moz-border-bottom-left-radius: 6px;
    -webkit-border-bottom-left-radius: 6px;
    border-bottom-left-radius: 6px;
    -moz-border-bottom-right-radius: 6px;
    -webkit-border-bottom-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

@media (max-width: 768px) {
    .navbar {
        -moz-border-bottom-left-radius: 0px;
        -webkit-border-bottom-left-radius: 0px;
        border-bottom-left-radius: 0px;
        -moz-border-bottom-right-radius: 0px;
        -webkit-border-bottom-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
}

.dropdown-item {
    background-color: #343a40;
    font-size: 14px;
    color: #ffffff;
}

.dropdown-menu {
    padding: 0;
}

.dropdown-menu.show {
    padding: 0;
}

.dropdown-divider {
    margin: 0;
}


/*
.nav-linkg {
}

.nav {
    text-align: left;
    font-size: 14px;
    background: #2d2d2d;
    -moz-border-bottom-left-radius: 6px;
    -webkit-border-bottom-left-radius: 6px;
    border-bottom-left-radius: 6px;
    -moz-border-bottom-right-radius: 6px;
    -webkit-border-bottom-right-radius: 6px;
    border-bottom-right-radius: 6px;
    margin: auto;
}

    .nav ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

        .nav ul li {
            display: inline;
            margin: 0;
            padding: 0;
        }

            .nav ul li:first-child {
                margin: 0 0 0 5px;
            }

            .nav ul li:last-child {
                margin: 0 5px 0 0;
            }

            .nav ul li a {
                display: inline-block;
                color: #f2f2f2;
                padding: 10px;
                text-decoration: none;
            }

                .nav ul li a:hover {
                    color: #565656;
                }
*/

.logo {
    height: 200px;
    max-width: 800px;
    text-align: left;
    margin: 0;
    padding: 5px;
    background-image: url("/Images/bkg2015.jpg");
    background-repeat: no-repeat;
    -moz-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    margin-bottom: 4px;
}

    .logo h1 {
        color: rgb(149,7,21);
    }

    .logo img {
        border: none;
    }

.leftmore a {
    margin-left: 5px;
    color: rgb(149,7,21);
    font-size: 12px;
    text-decoration: none;
}

    .leftmore a:hover {
        color: #4d4444;
    }

.sponsor {
    margin-left: 5px;
}

.WalletTop {
    padding-top: 20px;
}

.mintPageLogo {
/*    padding-top: 15vh;*/
    position:absolute;
    right:15%;
    top:15%;

    width: 5%;
/*    opacity: 0.8;*/
}

@media (max-width: 768px) {
    .mintPageLogo {
        right: 5%;
        top: 2%;
        width: 10%;
    }
}

.orderTotal {
    position: absolute;
    left: 15%;
    top: 20%;
    animation: textShadow 3s infinite, fadeIn 0.5s;
}

.windowName {
    position: absolute;
    left: 15%;
    top: 25%;
    animation: textShadow 3s infinite, fadeIn 0.5s;
}

.sliderDiv {
    position: absolute;
    left: 15%;
    top: 30%;
}

.balance {
    position: absolute;
    left: 15%;
    top: 40%;
    animation: textShadow 3s infinite, fadeIn 0.5s;
}

.errorMessage {
    border: 1px darkgreen dashed;
    width: 100%;
    padding: 5px;
    margin-top:60px;
    border-radius: 10px;
    font-size: small;
}

.mintSection {
    position: absolute;
    left: 15%;
    top: 35%;
}

@media (max-width: 768px) {
    .mintSection, .balance, .sliderDiv, .windowName, .orderTotal, .mintTitleTop {
        left: 5%;
    }
}



.mintSection {
    /*    padding-top: 5px;*/
}

h1 {
    font-size: 22px;
    font-weight: bold;
    color: black;
    padding: 5px;
    margin: 0 5px;
}

h2 {
    font-size: 20px;
    color: #9b9b9b;
    padding: 2px;
    margin: 0 5px;
}
.backButton {
    float: right;
    margin-top: -20px;
    margin-right: 2px;
    width: 30px;
}


/* these are used to size images on the inkhatech page */
div.w75 {
    width: 75px;
}
div.w98{
    width: 98px;
}
div.w114 {
    width: 114px;
}
div.w253 {
    width: 253px;
}
div.w320 {
    width: 320px;
}
div.w328 {
    width: 328px;
}
div.w375 {
    width: 375px;
}

h3 {
    font-size: 18px;
    color: #9b9b9b;
    padding: 2px;
    margin: 0 5px;
}

h4 {
    font-size: 16px;
    color: #9b9b9b;
    padding: 2px;
    margin: 0 5px;
}

p {
    /*    text-align: left;*/
    font-size: 13px;
    color: #2d2d2d;
    margin: 5px;
    padding: 0;
    line-height: 20px;
}

    p a {
        color: rgb(149,7,21);
        text-decoration: none;
    }

        p a:hover {
            color: #4d4444;
        }

.right {
    float: right;
    margin: 5px 10px 5px 0;
    font-size: 12px;
}

    .right a {
        color: #88aa00;
        text-decoration: none;
        padding: 5px;
    }

.nav2 {
    font-size: 14px;
    max-width: 800px;
    background: #2d2d2d;
    -moz-border-top-left-radius: 6px;
    -webkit-border-top-left-radius: 6px;
    border-top-left-radius: 6px;
    -moz-border-top-right-radius: 6px;
    -webkit-border-top-right-radius: 6px;
    border-top-right-radius: 6px;
    margin: 2px auto;
}

    .nav2 p {
        text-align: center;
        color: #f2f2f2;
        margin: 0 0px;
        padding: 0px 0px;
    }

        .nav2 p a {
            display: inline-block;
            color: #88aa00;
            padding: 0px;
            text-decoration: none;
        }

            .nav2 p a:hover {
                color: #565656;
            }

    .nav2 img {
        height: 18px;
        vertical-align: bottom;
    }

.clear {
    clear: both;
}

.content {
    -webkit-overflow-scrolling: touch;
    overflow: auto;
    max-width: 800px;
    text-align: center;
    margin: 0 auto;
}

.navBarLogo {
    width: 20px;
    height: 20px;
}

.options {
    max-width: 800px;
    text-align: center;
    margin: 0 auto;
}

    .options li {
        width: 100px;
        color: red;
    }

.centralText {
    text-align: center !important;
}

table {
    /*border-left: 1px solid #666666;
    border-right: 1px solid #666666;
    border-bottom: 1px solid #666666;
    color: #000;
	text-align: left;
	border-collapse: collapse;
	font-size: 80%;
	min-width: 320px;
	border-top-style: none;
    border-top-color: inherit;
    border-top-width: medium;*/
    /*    display:block;
    margin:auto;
    width:80%;*/
}

    table a {
        text-decoration: none;
    }

        table a:visited {
            text-decoration: none;
        }

tr:nth-child(odd) {
    /*background-color: #ebebeb;*/
}

.connectButton {
    display: none;
}

tr a {
    /*color: rgb(149,7,21);*/
}

tr:hover a {
    /*color: rgb(244,54,190);*/
}

tr:hover, tr.odd:hover {
    /*
	background-color: rgb(149,7,21);
	color: #ffffff;
    */
}

th {
    font-size: 105%;
    text-align: left;
    font-weight: bold;
    background-color: rgb(149,7,21);
    color: #ffffff;
    border-left: 1px solid #dadada;
}

tr {
    vertical-align: top;
}

tr, th, td {
    /*    padding: .75em;*/
}

td {
    /*border-left: 1px solid #dadada;*/
}


@keyframes textShadow {
    0% {
        text-shadow: 0.4389924193300864px 0 1px rgba(0,30,255,0.5), -0.4389924193300864px 0 1px rgba(255,0,80,0.3), 0 0 1px;
    }

    5% {
        text-shadow: 0.7928974010788217px 0 1px rgba(0,30,255,0.5), -0.7928974010788217px 0 1px rgba(255,0,80,0.3), 0 0 1px;
    }
    /** etc */
}
.crt:before {
    content: " ";
/*    display: block;*/
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius:50px;
    width:75%;
    height:40%;
    top:-230px;
    margin:auto;
    background: linear-gradient( to bottom, rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50% );
    background-size: 100% 4px;
    z-index: 2;
    pointer-events: none;
    opacity:1;
}

/*.screen {
    position: relative;
    width: 100%;
    height: 67.5vmin;
    border: 30px solid transparent;
    border-image-source: url(./bezel.png);
    border-image-slice: 30 fill;
    border-image-outset: 0;
    overflow: hidden;
}*/

.scanline {
    width: 75%;
    height: 50px;
    z-index: 8;
    background: linear-gradient( 0deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.2) 10%, rgba(0, 0, 0, 0.1) 100% );
    opacity: 0.2;
    position: absolute;
    left: 12.5%;
    bottom: 100%;
    animation: scanline 10s linear infinite;
    pointer-events: none;
}

@keyframes scanline {
    0% {
        bottom: 85%;
    }

    80% {
        bottom: 85%;
    }

    100% {
        bottom: 45%;
    }
}
