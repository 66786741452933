a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
    color: white;
}

html {
    font-size: 14px;
    background-color: transparent;
    color: white;
}

.navDiv {
    width: 568px;
/*    width: 80%;*/
    display: block;
    margin: auto;
    margin-bottom:10px;
}
a {
    color:white;
}

@media (min-width: 768px) {
    html { font-size: 16px; }
}

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }
